import { createRouter, createWebHistory } from 'vue-router';
import FormularioMaridaje from './components/FormularioMaridaje.vue';
import ResultadoMaridaje from './components/ResultadoMaridaje.vue';
import LandingMaridaje from './components/LandingMaridaje.vue';
import TerminosYCondiciones from './components/TerminosYCondiciones.vue';

const routes = [
  {
    path: '/',
    name: 'formulario',
    component: FormularioMaridaje
  },
  {
    path: '/resultado',
    name: 'resultado',
    component: ResultadoMaridaje,
    props: true
  },
  {
    path: '/tabla/:uuid',
    name: 'landing-maridaje',
    component: LandingMaridaje,
    props: true
  },
  {
    path: '/terminos',
    name: 'terminos',
    component: TerminosYCondiciones
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
