<template>
  <div class="container mt-5">
    <HeaderMaridador />
    <div class="container text-center">
      <h4>Resultado de tu maridaje</h4>
      <div class="ingredientes-seleccionados">
        <p><strong>{{ descripcion }}</strong></p>
      </div>

      <!-- Desplegar 5 coronas, algunas opacas y otras transparentes -->
      <div class="coronas">
        <img 
          v-for="index in 5" 
          :key="index" 
          :src="require(index <= coronas ? '../assets/corona.png' : '../assets/corona-transp.png')" 
          alt="Corona" 
          class="coronas-icon"
          style="max-height: 50px;"
        />
      </div>

      <!-- Mostrar la calificación y resumen -->
      <h2 class="mt-4">{{ rango }}</h2>

      <!-- Enlace para abrir modal de recomendaciones -->
      <a @click.prevent="mostrarModalRecomendaciones" class="recomendaciones-link">Para algunas recomendaciones: click aquí</a>

      <!-- Modal de recomendaciones -->
      <div v-if="mostrarRecomendaciones" class="modal-backdrop">
        <div class="modal-content">
          <!-- Botón de cierre como cruz "X" -->
          <span @click="cerrarModalRecomendaciones" class="close-button">&times;</span>
          <h3>Recomendaciones</h3>
          <div class="modal-body">
            <ul>
              <li v-for="(sugerencia, index) in sugerencias" :key="index">{{ sugerencia }}</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Botón de generar o mostrar la imagen generada con IA -->
      <button @click="generarImagenIA" class="btn btn-warning mt-3" :disabled="cargandoImagen">
        <span v-if="cargandoImagen" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span v-if="cargandoImagen" style="padding-left: 10px; font-size: 0.8em">{{ mensajeActual }}</span>
        <span v-else>{{ imagenGenerada ? 'Mostrar mi tabla' : 'Genera tu tabla con IA' }}</span>
      </button>

      <!-- Botones de navegación -->
      <button @click="editarMaridaje" class="btn btn-warning mt-3">
         Nuevo maridaje
      </button>

      <!-- Modal de imagen generada -->
      <div v-if="mostrarImagenGenerada" class="modal-backdrop">
        <div class="modal-content">
          <!-- Botón de cierre como cruz "X" -->
          <span @click="cerrarModalImagen" class="close-button">&times;</span>

          <!-- Texto de "¡Qué delicia!" -->
          <h3 class="delicia-titulo">¡Qué delicia!</h3>
          <p class="delicia-subtitulo">Lleva esta tabla a la realidad</p>

          <!-- Enlace para regenerar con ícono y spinner -->
          <a @click="regenerarImagenIA" class="recrear-link">
            <i v-if="!cargandoImagen" class="fas fa-redo-alt"></i>
            <span v-if="cargandoImagen" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-if="!cargandoImagen"> Regenerar</span>
            <span v-if="cargandoImagen">{{ mensajeActual }}</span>
          </a>

          <!-- Imagen generada -->
          <div class="modal-body">
            <img :src="imagenUrl" alt="Imagen generada" style="max-width: 100%; border-radius: 0px;">
          </div>

          <!-- Leyenda de IA debajo de la imagen -->
          <p class="leyenda-ia">Generado en kroonmaridaje.com con IA</p>

          <!-- Lista de ingredientes seleccionados -->
          <div class="ingredientes-lista mt-3">
            <h5>Ingredientes seleccionados:</h5>
            <ol>
              <li v-if="ingredientes.queso">{{ ingredientes.queso }}</li>
              <li v-if="ingredientes.crujiente">{{ ingredientes.crujiente }}</li>
              <li v-if="ingredientes.panesGalletas">{{ ingredientes.panesGalletas }}</li>
              <li v-if="ingredientes.dulce">{{ ingredientes.dulce }}</li>
              <li v-if="ingredientes.vegetal">{{ ingredientes.vegetal }}</li>
              <li v-if="ingredientes.charcuteria">{{ ingredientes.charcuteria }}</li>
              <li v-if="ingredientes.bebida">{{ ingredientes.bebida }}</li>
              <li v-if="ingredientes.extras">{{ ingredientes.extras }}</li>
            </ol>
          </div>

          <!-- Botón de compartir con ícono -->
          <button @click="compartirTabla" class="btn btn-warning mt-3">
            <i class="fas fa-share-alt"></i> Comparte tu tabla
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import HeaderMaridador from './HeaderMaridador.vue';

export default {
  components: {
    HeaderMaridador,
  },
  data() {
    return {
      resultado: this.$route.query.resultado, 
      ingredientes: this.$route.query.ingredientes,
      coronas: 0,
      calificacion: 0,
      rango: '',
      descripcion: '',
      resumen: '',
      sugerencias: [],
      mostrarRecomendaciones: false,
      mostrarImagenGenerada: false,
      cargandoImagen: false,
      imagenUrl: '',
      mensajeActual: 'Iniciando...', // Mensaje que se alternará
      imagenGenerada: false, // Variable para saber si ya se generó la imagen
      yaMostrada: false, // Variable para saber si ya se mostró la imagen una vez
    };
  },
  mounted() {
    const ingredientes = this.$store.getters.ingredientes;
    const resultado = this.$store.getters.resultado;

    if (ingredientes) {
      this.ingredientes = ingredientes;
    }

    if (resultado) {
      this.coronas = resultado.coronas;
      this.rango = resultado.rango;
      this.calificacion = resultado.calificacion;
      this.resumen = resultado.resumen;
      this.descripcion = resultado.descripcion;
      this.sugerencias = resultado.sugerencias;
    }
  },
  methods: {
    editarMaridaje() {
      this.$router.push({ name: 'formulario' });
    },
    mostrarModalRecomendaciones() {
      this.mostrarRecomendaciones = true;
    },
    cerrarModalRecomendaciones() {
      this.mostrarRecomendaciones = false;
    },
    generarImagenIA() {
  if (this.imagenGenerada && this.yaMostrada) {
    this.mostrarImagenGenerada = true;
    return;
  }

  this.cargandoImagen = true;
  this.imagenGenerada = false;

  const mensajes = [
    "Colocando el queso...",
    "Cortando ingredientes...",
    "Creando composición...",
    "Agregando detalles...",
    "Ajustando la luz...",
    "Últimos ajustes..."
  ];

  let index = 0;

  const intervalId = setInterval(() => {
    this.mensajeActual = mensajes[index];
    index++;

    if (index === mensajes.length) {
      clearInterval(intervalId);
    }
  }, 4000);

  this.llamarApiGeneraImagen(this.ingredientes)
    .then((response) => {
      this.imagenUrl = `${VUE_APP_IMAGE_PATH}${response.imagen_url}`;
      // this.imagenUrl = `/imagenes/${response.imagen_url}`;
      this.mostrarImagenGenerada = true;
      this.imagenGenerada = true;
      this.yaMostrada = true;
    })
    .finally(() => {
      this.cargandoImagen = false;
    })
    .catch((error) => {
      console.error("Error generando imagen:", error);
    });
},
    regenerarImagenIA() {
      this.yaMostrada = false;
      this.cargandoImagen = true;
      this.imagenGenerada = false;
      
      this.mensajeActual = "Colocando el queso..."; 
      
      this.generarImagenIA();
    },
    llamarApiGeneraImagen(ingredientes) {
      return fetch(`${process.env.VUE_APP_BACKEND_URL}/genera-imagen`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ingredientes)
      }).then(response => response.json());
    },
    cerrarModalImagen() {
      this.mostrarImagenGenerada = false;
    },
    compartirTabla() {
      const url = `${window.location.origin}/tabla/${this.imagenUrl.split('/').pop().replace('.png', '')}`;
      
      if (navigator.share) {
        navigator.share({
          title: 'Mi tabla de quesos',
          text: '¡Mira la tabla de quesos que he creado en Kroon!',
          url: url
        }).then(() => {
          console.log('Tabla compartida con éxito');
        }).catch(err => {
          console.log('Error al compartir:', err);
        });
      } else {
        alert('Tu navegador no soporta la funcionalidad de compartir.');
      }
    }
}
};
</script>

<style scoped>
h1 {
  color: #f1c40f;
}

.coronas {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.coronas-icon {
  font-size: 30px;
  margin: 0 5px;
}

/* Enlace estilizado para recomendaciones */
.recomendaciones-link {
  font-size: 14px;
  color: #a8946b;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
}

.recomendaciones-link:hover {
  color: #f1c40f;
}

/* Spinner del botón */
.spinner-border {
  margin-right: 5px;
}

/* Estilo del modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo oscuro */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Alineado hacia arriba */
  overflow-y: auto; /* Permite scroll */
  padding: 20px; /* Añade espacio para el scroll */
}

.modal-content {
  background-color: #2c2c2c; /* Fondo gris oscuro */
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  position: relative;
}

.modal-body {
  overflow-y: auto; /* Scroll dentro del modal */
  text-align: left; /* Alineación a la izquierda */
  padding: 40px 10px;
}

/* Leyenda para imagen generada con IA */
.leyenda-ia {
  font-size: 0.8rem;
  margin-top: 0px;
  color: #ccc;
  text-align: center;
}

/* Título "¡Qué delicia!" */
.delicia-titulo {
  font-size: 2rem;
  color: #f1c40f;
  margin-top: 10px;
  text-align: center;
}

/* Subtítulo "Lleva esta tabla a la realidad" */
.delicia-subtitulo {
  font-size: 1rem;
  color: #f1f1f1;
  text-align: center;
  margin-bottom: 10px;
}

ol {
  text-align: left;
  padding-left: 20px;
}

ol li {
  margin-bottom: 5px;
}

/* Botón de cierre en forma de cruz "X" */
.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
}

.close-button:hover {
  color: #ccc;
}

/* Estilo para la liga "Regenerar" */
.recrear-link {
  display: block;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  margin-bottom: 10px;
  text-decoration: none;
  position: absolute;
  top: 70px;
  left: 50px; 
  z-index: 1000;
}

.recrear-link:hover {
  text-decoration: underline;
}
</style>
