<template>
  <div class="container mt-5">
    <HeaderMaridador />
    <p class="text-light text-kroon">
      Corona tus ocasiones con las mejores tablas de quesos Kroon.
      Te ayudamos a crear combinaciones increíbles y sorprender a tus invitados.
      ¡Kroon creado para ti!
    </p>

    <form @submit.prevent="calificaMiCreacion" class="form-group">
      <div class="mb-4">
        <label for="quesoSeleccionado" class="form-label">Selecciona tu queso favorito</label>
        <select v-model="quesoSeleccionado" id="quesoSeleccionado" class="form-select" :class="{'is-invalid': !quesoSeleccionado && intentoEnvio}">
          <option disabled value="">Selecciona un queso Kroon</option>
          <option value="Gouda">Gouda</option>
          <option value="Edam">Edam</option>
          <option value="Maasdam">Maasdam</option>
          <option value="Procesado-ahumado-natural">Procesado ahumado natural</option>
          <option value="Procesado-ahumado-finas-hierbas">Procesado ahumado finas hierbas</option>
        </select>
        <div v-if="!quesoSeleccionado && intentoEnvio" class="invalid-feedback">
          Por favor selecciona un queso antes de continuar.
        </div>
      </div>

      <div v-if="quesoSeleccionado">
        <p class="text-center mb-4">Ahora selecciona los ingredientes que deseas maridar con tu Queso {{ quesoSeleccionado }}</p>

        <!-- Frutos secos/nueces -->
        <div class="mb-4">
          <p class="indicador">Frutos secos/nueces...</p>
          <div class="input-group">
            <input v-model="crujiente" class="form-control input-with-icon" placeholder="Almendras, Pistachos, Nuez pecana, etc..." />
            <span class="input-icon" @click="toggleSugerencias('frutosSecos')">
              <i class="far fa-lightbulb"></i>
            </span>
          </div>
          <p v-if="mostrarSugerencias === 'frutosSecos'" class="lista-sugerencias">
            <span v-for="(sugerencia, index) in sugerenciasFrutosSecos" :key="index">
              <a @click.prevent="agregarSugerencia('crujiente', sugerencia)" href="#">
                {{ sugerencia }}
              </a>
              <span v-if="index < sugerenciasFrutosSecos.length - 1">, </span>
            </span>
          </p>
        </div>

        <!-- Panes/galletas -->
        <div class="mb-4">
          <p class="indicador">Panes/galletas...</p>
          <div class="input-group">
            <input v-model="panesGalletas" class="form-control input-with-icon" placeholder="Baguette, Galletas saladas, Pretzels, etc..." />
            <span class="input-icon" @click="toggleSugerencias('panesGalletas')">
              <i class="far fa-lightbulb"></i>
            </span>
          </div>
          <p v-if="mostrarSugerencias === 'panesGalletas'" class="lista-sugerencias">
            <span v-for="(sugerencia, index) in sugerenciasPanesGalletas" :key="index">
              <a @click.prevent="agregarSugerencia('panesGalletas', sugerencia)" href="#">
                {{ sugerencia }}
              </a>
              <span v-if="index < sugerenciasPanesGalletas.length - 1">, </span>
            </span>
          </p>
        </div>

        <!-- Algo dulce -->
        <div class="mb-4">
          <p class="indicador">Algo dulce...</p>
          <div class="input-group">
            <input v-model="dulce" class="form-control input-with-icon" placeholder="Uvas, Fresas, Mermelada, etc..." />
            <span class="input-icon" @click="toggleSugerencias('dulce')">
              <i class="far fa-lightbulb"></i>
            </span>
          </div>
          <p v-if="mostrarSugerencias === 'dulce'" class="lista-sugerencias">
            <span v-for="(sugerencia, index) in sugerenciasDulce" :key="index">
              <a @click.prevent="agregarSugerencia('dulce', sugerencia)" href="#">
                {{ sugerencia }}
              </a>
              <span v-if="index < sugerenciasDulce.length - 1">, </span>
            </span>
          </p>
        </div>

        <!-- Algo vegetal -->
        <div class="mb-4">
          <p class="indicador">Algo vegetal...</p>
          <div class="input-group">
            <input v-model="vegetal" class="form-control input-with-icon" placeholder="Aceitunas, Pepino, Tomate cherry, etc..." />
            <span class="input-icon" @click="toggleSugerencias('vegetal')">
              <i class="far fa-lightbulb"></i>
            </span>
          </div>
          <p v-if="mostrarSugerencias === 'vegetal'" class="lista-sugerencias">
            <span v-for="(sugerencia, index) in sugerenciasVegetal" :key="index">
              <a @click.prevent="agregarSugerencia('vegetal', sugerencia)" href="#">
                {{ sugerencia }}
              </a>
              <span v-if="index < sugerenciasVegetal.length - 1">, </span>
            </span>
          </p>
        </div>

        <!-- Charcutería -->
        <div class="mb-4">
          <p class="indicador">Charcutería...</p>
          <div class="input-group">
            <input v-model="charcuteria" class="form-control input-with-icon" placeholder="Jamón serrano, Salami, Prosciutto, etc..." />
            <span class="input-icon" @click="toggleSugerencias('charcuteria')">
              <i class="far fa-lightbulb"></i>
            </span>
          </div>
          <p v-if="mostrarSugerencias === 'charcuteria'" class="lista-sugerencias">
            <span v-for="(sugerencia, index) in sugerenciasCharcuteria" :key="index">
              <a @click.prevent="agregarSugerencia('charcuteria', sugerencia)" href="#">
                {{ sugerencia }}
              </a>
              <span v-if="index < sugerenciasCharcuteria.length - 1">, </span>
            </span>
          </p>
        </div>

        <!-- Bebida -->
        <div class="mb-4">
          <p class="indicador">Bebida...</p>
          <div class="input-group">
            <input v-model="bebida" class="form-control input-with-icon" placeholder="Vino tinto, Cerveza clara, Mezcal, etc..." />
            <span class="input-icon" @click="toggleSugerencias('bebida')">
              <i class="far fa-lightbulb"></i>
            </span>
          </div>
          <p v-if="mostrarSugerencias === 'bebida'" class="lista-sugerencias">
            <span v-for="(sugerencia, index) in sugerenciasBebida" :key="index">
              <a @click.prevent="agregarSugerencia('bebida', sugerencia)" href="#">
                {{ sugerencia }}
              </a>
              <span v-if="index < sugerenciasBebida.length - 1">, </span>
            </span>
          </p>
        </div>

        <p class="indicador">¿Quieres agregar algo más?</p>
        <input v-model="extras" class="form-control" placeholder="Nueces, Almendras, etc..." />
      </div>

      <button type="submit" class="btn btn-warning btn-block" :disabled="!quesoSeleccionado || cargando">
        <span v-if="cargando" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span v-if="!cargando">Califica mi creación</span>
      </button>
      <!-- Liga a los términos y condiciones -->
      <p class="text-center mt-3">
        <a @click.prevent="mostrarTerminos" href="#" class="terminos-link">Términos y condiciones</a>
      </p>

      <!-- Modal de términos y condiciones -->
      <div v-if="mostrarModalTerminos" class="modal-backdrop">
        <div class="modal-content">
          <span @click="cerrarModalTerminos" class="close-button">&times;</span>
          <iframe src="/terminos" style="width: 100%; height: 800px; border: none;"></iframe>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import HeaderMaridador from './HeaderMaridador.vue';

export default {
  components: {
    HeaderMaridador,
  },
  data() {
    return {
      crujiente: '',
      panesGalletas: '',
      dulce: '',
      vegetal: '',
      charcuteria: '',
      bebida: '',
      quesoSeleccionado: '',
      extras: '',
      cargando: false,
      intentoEnvio: false,
      mostrarSugerencias: '',
      mostrarModalTerminos: false,

      // Sugerencias
      sugerenciasFrutosSecos: ['Almendras', 'Pasas', 'Avellanas', 'Pistachos', 'Nuez pecana', 'Castañas', 'Nuez de la india', 'Piñones', 'Macadamias', 'Nuez de Brasil'],
      sugerenciasPanesGalletas: ['Baguette', 'Pan de nueces y pasas', 'Pan de centeno', 'Ciabatta', 'Pan con aceitunas y orégano', 'Pan de masa madre', 'Galletas saladas', 'Galletas de avena', 'Pretzels'],
      sugerenciasDulce: ['Uvas', 'Fresas', 'Higos', 'Manzana', 'Pera', 'Zarzamoras', 'Mango', 'Toronja', 'Naranja', 'Mora azul', 'Mermeladas (fresa, durazno, etc)'],
      sugerenciasVegetal: ['Aceitunas', 'Tomates cherry', 'Pepino', 'Zanahorias', 'Apio', 'Pimiento', 'Jitomate deshidratado', 'Cebolla caramelizada', 'Pepinillos'],
      sugerenciasCharcuteria: ['Jamón serrano', 'Salami', 'Chorizo', 'Mortadela', 'Salchicha', 'Pastrami', 'Prosciutto', 'Pepperoni', 'Lomo embuchado', 'Salchichón'],
      sugerenciasBebida: ['Vino tinto', 'Vino blanco', 'Vino rosado', 'Vino espumoso', 'Cerveza clara', 'Cerveza oscura', 'Café', 'Mezcal', 'Limonada', 'Té', 'Agua de sabor'],
    };
  },
  methods: {
    toggleSugerencias(categoria) {
      this.mostrarSugerencias = this.mostrarSugerencias === categoria ? '' : categoria;
    },
    agregarSugerencia(campo, sugerencia) {
      if (!this[campo].includes(sugerencia)) {
        if (this[campo].length > 0) {
          this[campo] += ', ' + sugerencia;
        } else {
          this[campo] = sugerencia;
        }
      }
    },
    calificaMiCreacion() {
      this.intentoEnvio = true;

      if (!this.quesoSeleccionado) {
        return;
      }

      this.cargando = true;

      const ingredientes = {
        queso: this.quesoSeleccionado,
        crujiente: this.crujiente,
        panesGalletas: this.panesGalletas,
        dulce: this.dulce,
        vegetal: this.vegetal,
        charcuteria: this.charcuteria,
        bebida: this.bebida,
        extras: this.extras
      };

      fetch(`${process.env.VUE_APP_BACKEND_URL}/califica`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ingredientes)
      })
        .then(response => response.json())
        .then(data => {
          const resultadoLimpio = data.resultado.replace(/```json/g, '').replace(/```/g, '');
          this.$store.dispatch('actualizarIngredientes', ingredientes);
          this.$store.dispatch('actualizarResultado', JSON.parse(resultadoLimpio));
          this.$router.push({ name: 'resultado' });
        })
        .catch(error => {
          console.error('Error:', error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    mostrarTerminos() {
      this.mostrarModalTerminos = true;
    },
    cerrarModalTerminos() {
      this.mostrarModalTerminos = false;
    }
  }
};
</script>

<style scoped>
.spinner-border {
  margin-right: 5px;
}

/* Estilo para el input con ícono */
.input-with-icon {
  position: relative;
  padding-right: 40px;
}

/* Estilo para el enlace de términos y condiciones */
.terminos-link {
  color: #a8946b;
  text-decoration: underline;
  cursor: pointer;
}

.terminos-link:hover {
  color: #f1c40f;
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #000000;
  cursor: pointer;
}

.lista-sugerencias {
  background-color: rgba(0, 0, 0, 0.8); /* Fondo negro con opacidad */
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.1em;
  margin-top: 10px;
}

.lista-sugerencias a{
color: white;  
text-decoration: none;
}

/* Botón de cierre en forma de cruz "X" */
.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
}

.close-button:hover {
  color: #ccc;
}

</style>
