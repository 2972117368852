<template>
  <div class="container mt-5 text-center">
    <h1 class="text-light">Mi Tabla de Quesos</h1>

    <!-- Mostrar la imagen generada -->
    <div v-if="imagenUrl">
      <img :src="imagenUrl" alt="Tabla generada" class="img-fluid mt-4" style="border-radius: 10px;">
    </div>

    <p class="text-light mt-3">¡Crea tu propia tabla de quesos con Kroon!</p>

    <!-- Lista de ingredientes seleccionados -->
    <div v-if="ingredientes" class="ingredientes-lista mt-3">
      <h5>Ingredientes seleccionados:</h5>
      <ol>
        <li v-if="ingredientes.queso">{{ ingredientes.queso }}</li>
        <li v-if="ingredientes.crujiente">{{ ingredientes.crujiente }}</li>
        <li v-if="ingredientes.panesGalletas">{{ ingredientes.panesGalletas }}</li>
        <li v-if="ingredientes.dulce">{{ ingredientes.dulce }}</li>
        <li v-if="ingredientes.vegetal">{{ ingredientes.vegetal }}</li>
        <li v-if="ingredientes.charcuteria">{{ ingredientes.charcuteria }}</li>
        <li v-if="ingredientes.bebida">{{ ingredientes.bebida }}</li>
        <li v-if="ingredientes.extras">{{ ingredientes.extras }}</li>
      </ol>
    </div>

    <!-- Botón para crear tu propia tabla -->
    <button @click="irFormulario" class="btn btn-warning mt-3">Crear mi propia tabla</button>

    <!-- Botón para compartir la URL -->
    <button @click="compartirTabla" class="btn btn-warning mt-3">
      Compartir esta tabla
    </button>
  </div>
</template>

<script>
export default {
  props: ['uuid', 'ingredientes'],
  data() {
    return {
      imagenUrl: '',
    };
  },
  mounted() {
    // Cargar la imagen en base al UUID de la URL
    // this.imagenUrl = `/imagenes/${this.uuid}.png`;
    this.imagenUrl = `${process.env.VUE_APP_IMAGE_PATH}${this.uuid}.png`;

    // Si no se pasan ingredientes como props, podrías hacer una llamada a la API para recuperarlos
    if (!this.ingredientes) {
      this.obtenerIngredientes();
    }
  },
  methods: {
    irFormulario() {
      this.$router.push({ name: 'formulario' });
    },
    compartirTabla() {
      const url = `${window.location.origin}/tabla/${this.uuid}`;
      
      if (navigator.share) {
        navigator.share({
          title: 'Mi tabla de quesos',
          text: '¡Mira la tabla de quesos que hice con IA en kroonmaridaje.com!',
          url: url
        }).then(() => {
          console.log('Tabla compartida con éxito');
        }).catch(err => {
          console.log('Error al compartir:', err);
        });
      } else {
        alert('Tu navegador no soporta la funcionalidad de compartir.');
      }
    },
    obtenerIngredientes() {
      // Aquí puedes implementar una lógica de llamada a la API para obtener los ingredientes
      fetch(`${process.env.VUE_APP_BACKEND_URL}/obtener-ingredientes/${this.uuid}`)
        .then(response => response.json())
        .then(data => {
          this.ingredientes = data.ingredientes;
        })
        .catch(error => {
          console.error('Error al obtener los ingredientes:', error);
        });
    }
  }
};
</script>

<style scoped>
.container {
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 10px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
.text-light {
  color: #fff;
}

/* Lista de ingredientes */
.ingredientes-lista {
  text-align: left;
  color: #ffffff;
}

.ingredientes-lista ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.ingredientes-lista li {
  margin-bottom: 10px;
}
</style>
