<template>
  <div class="container mt-5">
    <h2>Términos y Condiciones</h2>
    <div class="terminos-body">
      <h4>1. Aceptación de Términos</h4>
      <p>Al utilizar el Maridador de Quesos Kroon, el usuario acepta los presentes términos y condiciones. El usuario entiende que cualquier creación generada por la herramienta, incluyendo la combinación de ingredientes, bebidas y quesos Kroon, está sujeta a las siguientes condiciones.</p>
      
      <h4>2. Uso de la Herramienta</h4>
      <p>El Maridador de Quesos Kroon está diseñado para ayudar a los usuarios a combinar diferentes ingredientes para crear una tabla de quesos perfecta. Los resultados generados por la herramienta son sugerencias basadas en las preferencias de los usuarios y no garantizan la satisfacción de todos los usuarios.</p>
      
      <h4>3. Limitaciones de la Herramienta</h4>
      <p>La herramienta se basa en inteligencia artificial para sugerir combinaciones. Sin embargo, es posible que algunos resultados no sean óptimos o que no se ajusten a las expectativas de todos los usuarios. Kroon no se hace responsable por la exactitud de las sugerencias.</p>
      
      <h4>4. Derechos de Propiedad Intelectual</h4>
      <p>Todo el contenido generado por el Maridador de Quesos Kroon, incluyendo imágenes, textos y combinaciones sugeridas, es propiedad de Kroon y está protegido por derechos de autor. El usuario tiene derecho a compartir las combinaciones generadas, siempre y cuando no infrinja los derechos de propiedad intelectual de Kroon.</p>
      
      <h4>5. Privacidad</h4>
      <p>Kroon se compromete a proteger la privacidad de los usuarios. Cualquier dato personal recolectado a través del uso de la herramienta será tratado de acuerdo con nuestra política de privacidad.</p>
      
      <h4>6. Modificaciones a los Términos</h4>
      <p>Kroon se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Es responsabilidad del usuario revisar periódicamente los términos actualizados.</p>
      
      <h4>7. Contacto</h4>
      <p>Si el usuario tiene alguna pregunta o comentario sobre estos términos y condiciones, puede ponerse en contacto con nosotros a través del correo electrónico <a href="mailto:contacto@kroon.com">contacto@kroon.com</a>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TerminosYCondiciones',
  methods: {
    volverAlFormulario() {
      this.$router.push({ name: 'formulario' });
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
  /* margin: 0 auto; */
  padding: 20px;
}

.terminos-body {
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
}

h2 {
  text-align: center;
  color: #a8946b;
  margin-bottom: 20px;
}

h4 {
  color: #f6e49e;
  margin-top: 20px;
}

a {
  color: #f1c40f;
  text-decoration: underline;
}

a:hover {
  color: #a8946b;
}
</style>

